import React from 'react';
import {getImage} from "../../contentful/data-provider";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import moment from "moment";
import BtnArrow from "../elements/btn-arrow";

function PressSingle(props) {
    const post = props.content;
    return (
        <>
            <section className="pb-[77px] md:pb-[140px]">
                <div className={'container'}>
                    <div className={'press-single-title max-w-[920px] mx-auto pb-[30px] md:pb-10 basis-full md:text-center'}>
                        <h2 className={'h2 text-black'}>
                            {post.title}
                        </h2>
                        <div className=" mt-1.5 md:mt-5 date">
                            {post.publishedAt}
                        </div>
                    </div>
                    <div className='max-w-[1140px] -mx-[30px] md:mx-auto'>
                        { (post.image && false) &&
                            <div className={`basis-full lg:basis-1/2 mb-[33px] md:mb-[70px] h-[300px] md:h-[400px]`}>
                                <img className='object-cover w-full h-full mb-6 md:mb-0'
                                    src={getImage(post.image).url} alt={getImage(post.image).title}
                                />
                            </div>
                        }
                    </div>
                    <div className={'max-w-[720px] mx-auto'}>
                        <div class="press-section">
                            <div className="press-single-content">
                                {renderRichText(post.body)}
                            </div>
                            {post.externalUrl &&
                                <a href={post.externalUrl} target={'_blank'} rel={'noreferrer'}>
                                    <BtnArrow class={'btn-yellow text-black mt-[30px]'}
                                              title={props.dataProvider ? props.dataProvider.getLabel('view-article') : 'label-provider-missing'}
                                              size={'btn-xxs'} rotate={true}
                                    />
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PressSingle;
