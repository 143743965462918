import * as React from "react";
import { graphql } from "gatsby";
// import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { DataProvider } from "../contentful/data-provider";
import PressSingleSection from "../components/sections/press-single-section";
import Seo from "../components/seo";

const PressSingle = ({ data }) => {
	const dataProvider = new DataProvider(data);
	const title = data.contentfulPressPost.title;

	const descriptionFromExcerpt = JSON.parse(data.contentfulPressPost?.excerpt?.raw ?? '')?.content[0]?.content[0]?.value

	return (
		<Layout dataProvider={dataProvider}>
			<Seo dataProvider={dataProvider} title={`Glassview | ${title.toUpperCase()}`} description={descriptionFromExcerpt}/>
			<main className='pt-24 lg:pt-24'>
				<PressSingleSection content={data.contentfulPressPost} dataProvider={dataProvider} />
			</main>
		</Layout>
	);
};

export default PressSingle;

export const query = graphql`
	query PressPostBySlug($slug: String!, $locale: String) {
		contentfulPressPost(slug: { eq: $slug }, node_locale: { eq: $locale }) {
			id
			image {
				url
			}
			node_locale
			title
			slug
			publishedAt(formatString: "MMMM DD, YYYY", locale: $locale)
			body {
				raw
			}
			excerpt {
				raw
			}
			externalUrl
		}
		allContentfulMenu(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					...Menus
				}
			}
		}
	}
`;
